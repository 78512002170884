<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 13.2L6.75001 18L5 16.4L10.25 11.6L10.2499 11.6L12 10L12 10L12.0001 10L13.7501 11.6L13.75 11.6L19 16.4L17.25 18L12 13.2Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 9.79995L17.25 5L19 6.6L13.75 11.4L13.7501 11.4L12 13L12 13L11.9999 13L10.2499 11.4L10.25 11.4L5 6.6L6.75001 5L12 9.79995Z"
      fill="black"
    />
  </svg>
</template>
